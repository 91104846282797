.landing-page {
  width: 100vw;
  height: 100vh;  
  font-family: 'Source Sans Pro', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;  
  text-transform: uppercase;
  padding-bottom: 20vh;
  font-weight: 300;
}

.landing-credit {
  font-size: 14px;
}

.landing-page > div {
  padding-bottom: 3vh;
}

.landing-header {
  letter-spacing: 30px;
  text-indent: 30px;
  text-align: center;
  font-size: 60px;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .landing-credit {
    font-size: 12px;
  }

  .landing-header {
    font-size: 40px;
    letter-spacing: 20px;
    text-indent: 20px;
  }
}

.landing-item {
  text-align: center;
  position: relative;
}
